<template>
  <div>
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <!-- 社会艺术水平考级查询 -->
      <van-form label-width="100px" v-if="params.type == 'test'">
        <div class="info-title">
          <span class="block"></span>
          <span class="text">考生个人信息</span>
        </div>
        <van-field
          required
          name="name"
          v-model="form.name"
          label="姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field required name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal" disabled>
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          required
          name="pinyin"
          v-model="form.pinyin"
          label="拼音"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="nation"
          v-model="form.nation"
          label="民族"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          readonly
          name="birthDateTime"
          :value="form.birthDateTime"
          label="出生日期"
          placeholder="请选择"
        ></van-field>
        <van-field
          required
          readonly
          name="documentType"
          :value="
            form.documentType == 1 ? '身份证' : '港澳台身份证/护照/台胞证及其他'
          "
          label="证件类型"
          placeholder="请选择"
        ></van-field>
        <van-field
          required
          name="idNumber"
          v-model="form.idNumber"
          label="证件号码"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <!-- <van-field required name="isCertificate" label="乐理证书">
          <template #input>
            <van-radio-group
              v-model="form.isCertificate"
              direction="horizontal"
              disabled
            >
              <van-radio :name="1">有</van-radio>
              <van-radio :name="0">无</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
        <van-field
          required
          name="certificateNumber"
          v-model="form.certificateNumber"
          label="证书编号"
          placeholder="请输入"
          v-if="form.certificateNumber"
          readonly
        >
        </van-field>
        <van-field
          required
          name="isTakeTheExam"
          label="是否参加乐理考试"
          readonly
        >
          <template #input>
            <van-radio-group
              v-model="form.isTakeTheExam"
              direction="horizontal"
              disabled
            >
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          required
          name="parentName"
          v-model="form.parentName"
          label="家长姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="parentPhone"
          v-model="form.parentPhone"
          label="家长手机号"
          placeholder="请输入"
          type="tel"
          readonly
        >
        </van-field>
        <van-field
          required
          name="parentEmail"
          v-model="form.parentEmail"
          label="电子邮箱"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="address"
          v-model="form.address"
          label="通讯地址"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="registrationAgencyName"
          v-model="form.registrationAgencyName"
          label="报名机构名称"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="teacherName"
          v-model="form.teacherName"
          label="教师姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="teacherPhone"
          v-model="form.teacherPhone"
          label="教师手机号"
          placeholder="请输入"
          type="tel"
          readonly
        >
        </van-field>
        <van-field required name="idPhoto" label="一寸证件照（蓝底、白底均可）">
          <template #input>
            <van-image
              width="100"
              height="100"
              :src="$ajax.imgUploadUrl(form.idPhotoUrl)"
              @click="viewPhoto(form.idPhotoUrl)"
            />
          </template>
        </van-field>
        <van-field label="乐理证书复印件">
          <template #input>
            <van-image
              width="100"
              height="100"
              :src="$ajax.imgUploadUrl(form.certificateUrl)"
              @click="viewPhoto(form.certificateUrl)"
            />
          </template>
        </van-field>
        <van-field
          required
          name="certificateLevel"
          v-model="form.certificateLevel"
          label="乐理等级"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field required label="户口本或身份证复印件">
          <template #input>
            <van-image
              width="100"
              height="100"
              :src="$ajax.imgUploadUrl(form.idCopyUrl)"
              @click="viewPhoto(form.idCopyUrl)"
            />
          </template>
        </van-field>
        <div class="info-title">
          <span class="block"></span>
          <span class="text">报考科目信息</span>
        </div>
        <van-field
          required
          name="examSubjectId"
          :value="form.examSubjectId"
          label="报考科目"
          placeholder="请选择"
          readonly
        ></van-field>
        <van-field
          required
          name="examLevelId"
          :value="form.examLevelId"
          label="报考级别"
          placeholder="请选择"
          readonly
        ></van-field>
        <van-field
          required
          name="songCategoryOne"
          v-model="form.songCategoryOne"
          label="报考曲目一"
          placeholder="请输入报考曲目"
          readonly
        ></van-field>
        <van-field
          required
          name="songCategoryTwo"
          v-model="form.songCategoryTwo"
          label="报考曲目二"
          placeholder="请输入报考曲目"
          readonly
        ></van-field>
      </van-form>
      <!-- 青少年报名考试 -->
      <van-form label-width="100px" v-if="params.type == 'teenager'">
        <div class="info-title">
          <span class="block"></span>
          <span class="text">个人信息</span>
        </div>
        <van-field
          required
          name="name"
          v-model="form.name"
          label="姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field required name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal" disabled>
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          required
          name="age"
          v-model="form.age"
          label="年龄"
          placeholder="请输入"
          type="digit"
          readonly
        >
        </van-field>
        <van-field
          required
          name="nation"
          v-model="form.nation"
          label="民族"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          readonly
          name="documentType"
          :value="form.documentType == 1 ? '身份证' : '护照/台胞证及其他'"
          label="证件类型"
          placeholder="请选择"
        ></van-field>
        <van-field
          required
          name="idNumber"
          v-model="form.idNumber"
          label="证件号码"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="parentName"
          v-model="form.parentName"
          label="家长姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="parentPhone"
          v-model="form.parentPhone"
          label="家长手机号"
          placeholder="请输入"
          type="tel"
          readonly
        >
        </van-field>
        <van-field
          required
          name="parentEmail"
          v-model="form.parentEmail"
          label="电子邮箱"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="registrationAgencyName"
          v-model="form.registrationAgencyName"
          label="报名机构名称"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field
          required
          name="teacherName"
          v-model="form.teacherName"
          label="指导教师姓名"
          placeholder="请输入"
          readonly
        >
        </van-field>
        <van-field required name="idPhoto" label="一寸证件照">
          <template #input>
            <van-image
              width="100"
              height="100"
              :src="$ajax.imgUploadUrl(form.idPhotoUrl)"
              @click="viewPhoto(form.idPhotoUrl)"
            />
          </template>
        </van-field>
        <van-field required name="v" label="户口本或身份证" readonly>
          <template #input>
            <van-image
              width="100"
              height="100"
              :src="$ajax.imgUploadUrl(form.idCopyUrl)"
              @click="viewPhoto(form.idCopyUrl)"
            />
          </template>
        </van-field>
        <div class="info-title">
          <span class="block"></span>
          <span class="text">报名信息</span>
        </div>
        <van-field
          required
          name="ageGroupValue"
          :value="form.ageGroupValue"
          label="组别"
          placeholder="请选择"
          readonly
        ></van-field>
        <van-field
          required
          name="mgName"
          :value="form.mgName"
          label="报名乐组"
          placeholder="请选择"
          readonly
        ></van-field>
        <van-field
          required
          name="miName"
          :value="form.miName"
          label="报名专业"
          placeholder="请选择"
          readonly
        ></van-field>
        <van-field
          required
          name="repertoire"
          v-model="form.repertoire"
          rows="2"
          autosize
          label="演奏曲目"
          type="textarea"
          placeholder="请输入演奏曲目"
          show-word-limit
          readonly
        ></van-field>
        <div v-for="(item, index) in form.otherTracks" :key="index">
          <van-field
            required
            name="ageGroup"
            :value="item.ageGroup"
            label="组别"
            placeholder="请选择"
            readonly
          ></van-field>
          <van-field
            required
            name="musicalInstruments"
            :value="item.musicalInstruments"
            label="报名乐组"
            placeholder="请选择"
            readonly
          ></van-field>
          <van-field
            required
            name="musicGroup"
            :value="item.musicGroup"
            label="报名专业"
            placeholder="请选择"
            readonly
          ></van-field>
          <van-field
            required
            name="repertoire"
            v-model="item.repertoire"
            rows="2"
            autosize
            label="演奏曲目"
            type="textarea"
            placeholder="请输入演奏曲目"
            show-word-limit
            readonly
          ></van-field>
        </div>
      </van-form>
    </div>
    <a :href="$ajax.imgUploadUrl(form.ossUrl)" target="_blank" download>
      <div class="download-btn">下载准考证</div>
    </a>
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import {
  Form,
  Field,
  RadioGroup,
  Radio,
  Dialog,
  ImagePreview,
  Image,
} from "vant";
export default {
  data() {
    return {
      isIndex: false,
      params: {
        name: "",
        idNumber: "",
        type: "",
      },
      form: {},
    };
  },
  components: {
    "van-form": Form,
    "van-field": Field,
    "van-radio-group": RadioGroup,
    "van-radio": Radio,
    "van-image": Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Dialog.Component.name]: Dialog.Component,
    Nav,
    Footer,
  },
  mounted() {
    this.params = this.$route.query;
    if (this.params.type == "test") {
      this.getExamRegistrationDetail();
    } else {
      this.getCompetitionDetail();
    }
  },
  methods: {
    // 社会水平艺术考试
    getExamRegistrationDetail() {
      this.$ajax
        .get(
          this.$ajax.localOrOnline() + "examRegistration/registration/info",
          this.params
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              let data = res.data;
              let admissionTicket = data.admissionTicket;
              let er = data.er;
              let newData = {};
              Object.assign(newData, admissionTicket, er);
              newData.examLevelId = data.el.level;
              newData.examSubjectId = data.es.subject;
              this.form = newData;
              this.form.songCategoryOne = data.elsc.songCategory;
              this.form.songCategoryTwo = data.elscTwo.songCategory;
              this.form.certificateLevel = data.cl.value;
              this.$forceUpdate();
            } else {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "没有获取到您的报名信息",
                })
                .then(() => {});
            }
          } else {
            this.$toast.fail(res.message);
          }
        });
    },
    // 青少年大赛
    getCompetitionDetail() {
      this.$ajax
        .get(
          this.$ajax.localOrOnline() + "instrument/competition/info",
          this.params
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              let data = res.data;
              this.form = data.ic;
              this.form.mgName = data.mg.name;
              this.form.miName = data.mi.name;
              this.form.ageGroupValue = data.ageGroup.value;
              this.form.otherTracks = data.otherTracks || [];
              this.$forceUpdate();
            } else {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "没有获取到您的报名信息",
                })
                .then(() => {});
            }
          } else {
            this.$toast.fail(res.message);
          }
        });
    },
    // 查看照片
    viewPhoto(url) {
      let photoUrl = this.$ajax.imgUploadUrl(url);
      ImagePreview({
        images: [photoUrl],
      });
    },
  },
};
</script>
<style scoped>
.download-btn {
  width: 150px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: #bf783d;
  border-radius: 4px;
  margin: 20px auto;
  cursor: pointer;
}
.info-title {
  padding: 10px;
  background: #fff;
  margin: 10px 0 0;
}

.block,
.text {
  display: inline-block;
  vertical-align: middle;
}

.block {
  width: 5px;
  height: 20px;
  background: #1989fa;
}

.text {
  margin-left: 5px;
  color: #666;
  font-size: 14px;
}
.avatar-uploader {
  background: red !important;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
}
.el-icon-plus {
  width: 150px;
  height: 150px;
  font-size: 30px;
}
</style>